<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <br />
        <v-card-text class="text-center">
          <v-avatar size="400">
            <v-img
              :src="imgAltaCalidad"
              class="ma-5"
            ></v-img>
          </v-avatar>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card outlined tile class="mx-auto my-12" max-width="90%">
      <v-card-title
        >Inscritos
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <transition name="slide-fade">
        <div v-if="alerta">
          <v-alert text outlined dense :type="tipoAlerta">{{ mensajeAlerta }}</v-alert>
        </div>
      </transition>

      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="15"
        class="elevation-1"
        :loading="loading"
        :search="search"
      >
        <template v-slot:item.foto="{ item }">
          <v-avatar style="cursor: pointer" @click="verFoto(item)">
            <img :src="item.foto" alt="John" />
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import utility from "@/js/utility";
export default {
  data: () => ({
    dialog: false,
    search: null,
    loading: false,
    headers: [
      {
        text: "Foto",
        align: "start",
        sortable: false,
        value: "foto",
      },
      { text: "Nombre Completo", value: "nombre_completo" },
      { text: "Dirección", value: "direccion" },
      { text: "Teléfono", value: "telefono" },
      { text: "Correo", value: "correo" },
      { text: "Último grado de estudio", value: "grado_de_estudio" },
      { text: "Especialidad", value: "especialidad" },
      { text: "Fecha de registro", value: "fecha_creacion" },
    ],
    desserts: [],

    alerta: false,
    mensajeAlerta: null,
    tipoAlerta: null,
    intervalo: null,

    imgAltaCalidad: null,
  }),
  mounted() {
    this.VIEW_COMPONENETS_MUTATION(false);
    this.listaInscritos();
  },
  computed: {
    ...mapState(["viewComponents"]),
  },
  methods: {
    ...mapMutations(["VIEW_COMPONENETS_MUTATION"]),
    verFoto: function (item) {
      utility.fotoSuscriptor(item.id).then((res) => {
        if (res.data.mensaje == "encontrado") {
          this.dialog = true;
          this.imgAltaCalidad = res.data.foto;
        } else {
          this.alerta = true;
          this.mensajeAlerta = "Ha ocurrido un error inesperado, trata de nuevo";
          this.tipoAlerta = "error";
        }
      });
    },

    listaInscritos: function () {
      this.loading = true;
      utility
        .lista()
        .then((res) => {
          this.desserts = res.data.datos;

          console.log(res.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    alerta(val) {
      if (val) {
        this.intervalo = setInterval(() => {
          this.alerta = false;
          clearInterval(this.intervalo);
        }, 5000);
      }
    },
  },
};
</script>
