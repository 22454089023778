<template>
  <div>
    <v-container grid-list-xl text-xs-center>
      <v-layout row wrap>
        <v-flex xs6 offset-xs3>
          <br />
          <br />
          <v-card color="dark" elevation-24 class="mt-15 pb-15">
            <v-card-text>
              <h4 class="display-2 pa-5">Iniciar de Sesión</h4>

              <hr />
              <br />

              <form
                ref="entrarLogin"
                @submit.prevent="entrar"
                enctype="multipart/formdata"
              >
                <v-text-field
                  clearable
                  v-model="username"
                  label="Nombre de usuario"
                  prepend-icon="mdi-account"
                  type="text"
                  @click:prepend="prependClicked"
                  
                  @click:append-outer="appendClicked"
                  counter="255"
                >
                </v-text-field>
                <v-text-field
                  clearable
                  v-model="password"
                  label="Contraseaña"
                  prepend-icon="mdi-lock"
                  type="password"
                  @click:prepend="prependClicked"
                  
                  @click:append-outer="appendClicked"
                >
                </v-text-field>
                <transition name="slide-fade">
                  <div v-if="alerta">
                    <v-alert text outlined dense :type="tipoAlerta">{{
                      mensajeAlerta
                    }}</v-alert>
                  </div>
                </transition>
                <br />
                <v-btn
                  color="primary"
                  large
                  class="float-right"
                  type="submit"
                  :loading="cargaBoton"
                  :disable="cargaBoton"
                  >Entrar</v-btn
                >
              </form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import utility from "@/js/utility";

import { mapState, mapMutations } from "vuex";
export default {
  data: () => ({
    username: "",
    password: "",
    cargaBoton: false,

    alerta: false,
    mensajeAlerta: null,
    tipoAlerta: null,
    intervalo: null,
  }),
  mounted() {
    this.VIEW_COMPONENETS_MUTATION(false);
  },
  computed: {
    ...mapState(["viewComponents"]),
  },
  methods: {
    ...mapMutations([
      "VIEW_COMPONENETS_MUTATION",
      "TOKEN_MUTATION",
      "DATOS_USUARIOS_MUTATION",
      "LOGGED_IN_MUTATION",
    ]),
    prependClicked() {
      console.log("prepend clicked");
    },
    appendClicked() {
      console.log("append clicked", this.username);
    },

    entrar() {
      this.cargaBoton = true;
      const datos = {
        usuario: this.username,
        clave: this.password,
      };

      utility
        .iniciarSesion(datos)
        .then((res) => {
          switch (res.data.mensaje) {
            case "usuarioExiste":
              this.TOKEN_MUTATION(res.data.token);
              this.DATOS_USUARIOS_MUTATION(res.data.datosUsuario);
              this.LOGGED_IN_MUTATION(true);
              console.log(res.data);
              this.$router.push("inscritos");
              break;
            default:
              this.alerta = true;
              this.mensajeAlerta = "Usuario no encontrado";
              this.tipoAlerta = "error";
              break;
          }
        })
        .finally(() => {
          this.cargaBoton = false;
        });
    },
  },

  watch: {
    alerta(val) {
      if (val) {
        this.intervalo = setInterval(() => {
          this.alerta = false;
          clearInterval(this.intervalo);
        }, 5000);
      }
    },
  },
};
</script>
