<template>
  <section id="home">
    <v-row no-gutters>
      <v-col cols="12">
        <Hero />
        <CalloutBlock />
        <Brands />
        <Testimonials />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Hero from "@/components/sections/Hero";
import CalloutBlock from "@/components/sections/CalloutBlock";
import Brands from "@/components/sections/Brands";
import Testimonials from "@/components/sections/Testimonials";

export default {
  name: "Home",

  components: {
    Hero,
    CalloutBlock,
    Brands,
    Testimonials,
  },
   mounted() {
    window.scrollTo(0, 0);
  },

};
</script>
